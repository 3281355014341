import React, { Fragment } from "react";

import Footer from "../components/footer/footer";
import Seo from "../components/seo/seo";
import Posts from "../components/blog/all-posts";
import Title from "../components/text/title";

export default function Blog() {
  return (
    <Fragment>
      <section className="bg-background3">
        <Title
          text="Blog"
          containerStyle="px-5 mx-auto py-12 text-white text-8xl text-center "
        />
        <Posts />
        <Footer />
      </section>
    </Fragment>
  );
}

export const Head = () => {
  return <Seo title={"Blog"} />;
};
